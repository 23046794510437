@font-face {
    font-family: FS Elliot Pro-Regular;
    src: url(Fonts/FSElliotPro-Regular.otf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
    color: #464646;
}

body {
    font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif !important;
}

.nav_bg {
    background-color: #ffffff !important;
    box-shadow: 0 0 25px 0 rgba(70, 78, 126, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100;
    z-index: 99;
}

.navbar-toggler {
    border: none !important;
}

.header-nav .nav-item .nav-link {
    color: #464646 !important;
    opacity: 1;
    line-height: 26px;
    padding: 15px 0;
    font-size: 18px !important;
    cursor: pointer;
}

.header-nav .show>.nav-link {
    color: #0091da !important;
}

.header-nav .show>.nav-link>.nav-profile {
    content: url("assets/Profile_active.png");
}

.navbar a {
    font-size: 18px;
    text-transform: capitalize;
    padding: 0;
}

.menu-active {
    color: #0091da !important;
}

.navbar a:hover {
    color: #0091da !important;
}

.navbar-nav {
    margin-left: auto;
}

.header-nav .nav-item {
    margin-right: 35px;
}

.header-nav .profile-link {
    padding: 4px 0 !important;
}

.profile-link img {
    height: 48px;
    width: 48px;
}

.nav {
    flex-wrap: nowrap;
    overflow-y: auto;
}


/*Landing Sub Navbar */

.sub-nav-bg {
    background-color: #f8f9fa !important;
    color: #464646;
    top: 90px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 90;
}

@media screen and (max-width: 991px) {
    .sub-nav-bg {
        background-color: #f8f9fa !important;
        color: #464646;
        top: 74px;
        position: -webkit-sticky;
        position: sticky;
        z-index: 90;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .sub-nav-bg {
        top: 55px;
    }
}

.inner-div {
    padding-left: 30px;
    padding-right: 30px;
}

.sub-navigation a {
    white-space: nowrap;
    display: block;
    color: #464646;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 22px;
    padding: 18px 0;
    margin-right: 24px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.nav-link.active {
    border-bottom: 2px solid #0091da;
    color: #0091da;
    text-decoration: none;
    cursor: pointer;
}


/*Main Content */

.main-content {
    background: linear-gradient(#eaf6f7, #ffffff);
    padding: 35px 0px;
}

.info-card-content {
    background: linear-gradient(#eaf6f7, #ffffff);
    padding: 39px 0px 35px 0px;
}

.info-card-content-aiib {
    /* background: linear-gradient(#eaf6f7, #ffffff); */
    padding: 39px 0px 35px 0px;
}


/*Breadcrumbs */


/* .breadcrumb li a {
  margin-left: 10px;
  text-decoration: none;
} */

.breadcrumb-item.active a {
    color: #0091da;
    text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "";
    padding-left: 0;
}

.breadcrumb-item img {
    height: 12px;
    width: 12px;
    margin-right: 5px;
}


/*Footer*/

.bg-dark {
    background-color: #464646 !important;
}

.footer-office-details {
    padding: 55px 0 60px 0;
}


/* .footer-office-details .footer-download-app h4,
.footer-office-details .footer-download-app p,
.footer-office-details .address h4,
.footer-office-details .address p {
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  line-height: 26px;
} */

.footer-office-details .time {
    font-size: 15px;
    line-height: 22px;
}

.footer-office-details .time span {
    display: block;
}

.footer-office-details .time span,
.footer-office-details .time p {
    color: #ffffff;
}


/*.follow {
  margin-top: 85px;
}*/

.follow-icons a {
    margin-right: 15px;
}

.footer-copyright-text {
    margin-top: 50px;
}

.footer-copyright-text p,
.footer-copyright-text a {
    color: rgba(255, 255, 255, 0.4);
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0px;
}

.footer-copyright-text a:first-child::after {
    content: "|";
    margin: 0px 6px 0px 8px;
}

.footer-copyright-text a:nth-child(2)::after {
    content: "|";
    margin: 0px 6px 0px 8px;
}

.app-download-icon {
    margin-right: 15px;
}

.app-download-icon img {
    height: 40px;
    width: 140px;
}

.text-labels {
    color: #494f50 !important;
    font-size: 15px;
    letter-spacing: 0;
    padding: 10px 0px 0px 15px;
    white-space: nowrap;
}

.align-text-data {
    text-indent: 15px;
}

.left-align {
    color: #464646;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    font-size: 20px;
}

.inner-label {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    margin-top: 5px;
    display: block;
    color: #464646;
    word-break: break-word;
}

.info-body {
    text-rendering: optimizeLegibility;
    overflow-x: hidden !important;
    box-sizing: content-box !important;
    border-radius: 0.75rem !important;
}

.info-title {
    font-size: 24px;
    color: #464646;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 24px;
}

.info-link {
    font-size: 13px;
    color: #0091da;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 22px;
    text-decoration: none;
}

.pds-card {
    border-radius: 0.75rem;
    height: auto;
    box-shadow: 0 0 25px 0 rgba(70, 78, 126, 0.1);
    margin-bottom: 30px;
}

.pds-card .card-body {
    padding: 44px 65px;
}


/* .container {
  width: 95% !important;
  margin: 0 auto;
  padding: 20px !important;
  position: relative;
  font-family: "FS Elliot Pro-Regular", Helvetica, Arial, sans-serif;
} */

.text-labels-data {
    color: #494f50 !important;
    font-size: 15px;
    letter-spacing: 0;
    /* margin-top: 1.25rem; */
    padding: 15px 0px 0px 30px;
}

.errorMessage {
    color: red;
    font-size: 15px;
    display: block;
    left: 30px;
    position: relative;
    margin-top: 5px;
}

.otherdetailselect {
    width: 100%;
    height: 48px;
    font-weight: bold;
}

.form-margin {
    margin-top: -0.75rem;
}

.mobile-number {
    margin-top: 28px;
}

.lbl-verifypin {
    height: 48px;
}

.btn-cancel {
    color: #464646;
}

.grey {
    color: #464646;
}

.btn-blue {
    border-radius: 34px !important;
    display: block;
    font-size: 18px;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer !important;
    margin-top: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    background: linear-gradient(207deg, #00c4d9 0%, #0091da 100%);
    text-decoration: none;
    padding: 10px 25px;
    border: none;
    outline: none;
    height: 52px;
    width: 147px;
}

.pds-button:hover,
.pds-button:disabled {
    border-radius: 34px !important;
    color: #ffffff;
    display: block;
    font-size: 18px;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer !important;
    margin-top: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    background: #c7c7c7 !important;
    text-decoration: none;
    padding: 10px 25px;
    border: none;
    outline: none;
    height: 52px;
    width: 147px;
}

.othercontacterrror {
    margin-left: -31px;
}

@media only screen and (max-width: 320px) {
    .lbl-verifypin {
        height: 65px;
    }
}

.other-con-info-link {
    padding-left: 0px;
    color: #000000 !important;
    letter-spacing: 0;
    line-height: 26px;
    font-size: 18px;
}

.note-title {
    padding-left: 0px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #464646;
}

.note-text {
    margin-bottom: 10px;
    padding-left: 0px;
    color: #464646;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
    display: block;
    font-size: 18px;
}

.other-con-note a {
    color: #0091da !important;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.spinner {
    color: #0091da !important;
}

.pds-modal-dialog {
    text-align: justify;
}

.notification-label {
    text-align: justify;
}

.mobile-select {
    margin-bottom: 10px;
}

.header-nav li:last-child {
    margin-right: 0;
}

@media screen and (max-width: 767px) {
    .rsc-navbar .rsc-logo {
        width: auto;
        height: 33px;
    }
}


/* @media screen and (max-width: 991px) {
  .navbar-brand {
    margin-left: 0;
    margin-right: 30px;
    padding-bottom: 15px;
  }
} */

@media screen and (max-width: 991px) {
    .navbar-light .navbar-toggler {
        outline: none;
        border: none;
        color: #464646;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }

    .header-nav .profile-link {
        padding: 15px 15px 20px 15px !important;
    }

    .navbar-collapse.show {
        margin-top: 12px;
    }
}

.rsc-navbar {
    padding: 20px 40px !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: #ffffff !important;
    box-shadow: 0 0 25px 0 rgb(70 78 126 / 10%);
    position: sticky;
}

@media screen and (max-width: 991px) {
    .rsc-navbar {
        position: fixed;
        padding: 12px 15px !important;
    }

    .main-content {
        padding-top: 57px;
    }

    .break-line {
        display: none;
    }

    .btn-blue,
    .btn-blue:hover,
    .btn-blue:disabled {
        width: 125px;
        font-size: 16px;
        line-height: 22px;
    }

    .footer-office-details {
        padding: 30px 12px 12px 12px;
    }
}

img {
    vertical-align: middle;
    border-style: none;
}

@media screen and (max-width: 991px) {
    .navbar-light .navbar-toggler-icon {
        width: 20px;
        height: 20px;
    }
}

.gotoArrow {
    margin-left: 5px;
}

.form-input {
    display: block;
    width: 100%;
    height: 52px !important;
    border: 1px solid rgba(70, 78, 126, 0.15) !important;
    border-radius: 40px !important;
    background-color: #ffffff !important;
    color: #464646 !important;
    padding-left: 20px !important;
    padding-right: 18px !important;
}

.rsc-select {
    display: block;
    width: 100%;
    border: 1px solid rgba(70, 78, 126, 0.15) !important;
    border-radius: 40px !important;
    color: #464646 !important;
}

@media screen and (max-width: 991px) {
    .header-nav .nav-item {
        position: relative;
        border-top: 1px solid rgba(70, 78, 126, 0.15);
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 991px) {
    .navbar-light .navbar-nav .nav-link {
        opacity: 1;
        line-height: 26px;
        padding: 15px 20px 15px 20px !important;
        font-weight: bold;
    }
}

@media screen and (max-width: 991px) {
    .navbar-nav {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

@media screen and (max-width: 991px) {
    .header-nav .nav-item {
        margin: 0px !important;
    }
}

.goto-link {
    text-decoration: none !important;
    color: #464646;
    font-size: 15px;
    font-weight: bold;
}

.footer-office-details .contact h4,
.footer-office-details .follow-us h4 {
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    line-height: 26px;
}

.footer-office-details .contact p {
    font-size: 15px;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
}

.goto-link:hover {
    color: #0091da;
}

.addr-formInput {
    border-radius: 25px !important;
}

.margin-select-top {
    margin-top: 0px;
}

.pds-tab .pds-tab-list button.pds-tab-button[aria-selected="true"] {
    color: #464646;
    background-color: transparent;
    border: none;
    border-bottom: 1.8px solid #0091DA;
    font-weight: bold;
}

.pds-tab .pds-tab-list button.pds-tab-button[aria-selected="false"] {
    color: #464646;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(70, 78, 126, 0.15);
}

.pds-tab .pds-tab-list button.pds-tab-button:hover {
    background-color: transparent;
    color: #464646;
    opacity: 1;
    font-weight: bold;
}

.button.pds-tab-button {
    font-size: 20px;
    line-height: 26px;
    color: #464646;
}

.frgt-pin-btn {
    background-color: #ececec !important;
    color: #464646 !important;
    font-size: 16px solid;
    line-height: 22px;
    border-radius: 34px;
    width: 100%;
    height: 50px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 25px;
}

.frgt-pin-btn:hover {
    background-color: #797979 !important;
    color: #ffffff !important;
    border-radius: 34px;
    width: 100%;
    height: 50px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 25px;
}

.login-pin {
    margin-bottom: -30px;
}

@media (min-width: 410px) and (max-device-width: 455px) {
    .other-con-info-link {
        font-size: 13px;
    }

    .note-title {
        font-size: 16px;
        line-height: 22px;
    }

    .note-text {
        font-size: 13px;
        color: #797979;
    }
}

@media (min-width: 373px) and (max-device-width: 410px) {
    .other-con-info-link {
        font-size: 13px;
    }

    .note-title {
        font-size: 16px;
        line-height: 22px;
    }

    .note-text {
        font-size: 13px;
        color: #797979;
    }
}

@media (min-width: 344px) and (max-device-width: 373px) {
    .other-con-info-link {
        font-size: 13px;
    }

    .note-title {
        font-size: 16px;
        line-height: 22px;
    }

    .note-text {
        font-size: 13px;
        color: #797979;
    }
}

.login-padding {
    margin-left: 150px !important;
}

.note-padding {
    margin-left: 149px;
}

.login-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #464646;
    margin-bottom: 15px;
}

@media (min-width: 514px) and (max-device-width: 575px) {
    .schemeSelection {
        width: 46.5%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

@media (min-width: 455px) and (max-device-width: 513px) {
    .schemeSelection {
        width: 46%;
    }

    .icon-schemaSelection {
        margin-left: 39%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

@media (min-width: 410px) and (max-device-width: 455px) {
    .schemeSelection {
        width: 45.5%;
    }

    .icon-schemaSelection {
        margin-left: 36%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

@media (min-width: 373px) and (max-device-width: 410px) {
    .schemeSelection {
        width: 45%;
    }

    .icon-schemaSelection {
        margin-left: 33%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

@media (min-width: 344px) and (max-device-width: 373px) {
    .schemeSelection {
        width: 44.5%;
    }

    .icon-schemaSelection {
        margin-left: 32%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

@media (min-width: 319px) and (max-device-width: 344px) {
    .schemeSelection {
        width: 44%;
    }

    .icon-schemaSelection {
        margin-left: 30%;
    }

    .login-padding {
        margin-left: auto !important;
    }

    .note-padding {
        margin-left: auto;
    }
}

.schemeSelection {
    background-color: #ffffff;
    color: #464646;
    font-size: 15px;
    line-height: 17px;
    margin-right: 15px;
    border-radius: 10px;
    background-position: left top;
    background-repeat: repeat;
    padding: 20px;
    margin-bottom: 15px;
}

.icon-schemaSelection {
    margin-bottom: 11%;
    margin-left: 42%;
    display: inline-block;
}

.forgotpin-scheme {
    margin-top: 30px;
}

.schemeselect-checkmark:after {
    content: "?";
    color: white;
    position: absolute;
    text-align: center;
    line-height: 25px;
    width: 30px;
    height: 30px;
    background: #797979;
    border-radius: 50%;
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.schemedeselect-checkmark:after {
    content: "?";
    color: white;
    position: absolute;
    text-align: center;
    line-height: 25px;
    width: 30px;
    height: 30px;
    background: #b1b1b1;
    border-radius: 50%;
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-label {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 16px;
    display: block;
    color: #767676;
    white-space: nowrap;
}

.justify-label>ul>li {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
    display: block;
    color: #767676;
}

.edit-link {
    font-size: 18px;
    color: #0091da;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-decoration: none !important;
}

.edit-link.inactive {
    pointer-events: none;
    cursor: default;
    color: #C7C7C7;
}

.checkbox-label {
    font-weight: normal;
    font-size: 18px !important;
    height: 26px !important;
    margin-top: 20px;
    display: block;
    color: #464646 !important;
}


/*
@media (max-width: 768px){
  .address{
    border-top: 1px solid #ffffff;

  }
}*/

.mpf {
    color: #464646;
    font-size: 18px !important;
    text-decoration: none;
    line-height: 26px;
}

.divider {
    color: #979797 !important;
    height: 13px !important;
    text-decoration: none;
    margin: 0 12px;
}

.other-con-note {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
    padding-left: 0px;
    display: block;
    color: #767676;
}

.other-con-note-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
    padding-left: 0px;
    display: block;
    color: #464646;
}

.lbl-scheme {
    margin-bottom: 30px;
}

.forgotpinsuccesstext {
    text-align: center;
}

.forgotpinerrornotifi {
    background-color: #ececec;
    border-radius: 25px;
    background-position: left top;
    background-repeat: repeat;
    padding: 20px;
    margin-bottom: 15px;
    margin-left: 12%;
    width: 530px;
}

.forgot-btn {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    border-radius: 34px;
    background: linear-gradient(206.57deg, #00c4d9 0%, #0091da 100%);
    width: 100%;
    height: 50px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    padding: 5px 25px;
}

.forgot-btn:disabled {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    border-radius: 34px;
    background: #c7c7c7 !important;
    width: 100%;
    height: 50px;
    display: block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    padding: 4px 25px;
}

.forgot-btn:focus {
    outline: none !important;
}

.forgot-reset {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    box-sizing: border-box;
    height: 60px;
    width: 470px;
    border: 1.5px solid rgba(0, 145, 218, 0.2);
    border-radius: 34px;
    background: white;
    width: 100%;
    height: 50px;
    display: block;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin: 25px 0px 25px 0px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 25px;
}

.btn-primary:disabled,
btn-sign:disabled {
    opacity: 0.65;
}

.btn-primary:hover,
.btn-primary:visited,
.btn-sign:hover,
.btn-sign:visited {
    background: #e8f3f8 !important;
    color: #0091da !important;
}

.btn-primary {
    color: #ffffff;
    background: linear-gradient(206.57deg, #00c4d9 0%, #0091da 100%);
}

.reqiuredfielderrmsg {
    margin-left: 0px !important;
}

.popupbutton {
    display: none;
}

.select-input-bold {
    font-weight: bold;
}

.hr-line {
    margin-left: -25px;
    margin-right: -25px;
    color: #ffffff;
    margin-top: 58px;
    margin-bottom: 25px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.address .header,
.address .info p {
    color: #ffffff;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 20px;
}

.address .info p {
    margin-bottom: 5px;
}

.contact .header {
    color: #ffffff;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 20px;
}

.contact .info p {
    color: #ffffff;
    font-family: "FS Elliot Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 5px;
}

.contact .info a {
    color: #ffffff;
}

.time {
    margin-top: 25px;
}

.time p {
    margin-bottom: 15px;
}

.copyright {
    margin-top: 20px;
}

.footer-download-app .heading {
    color: #ffffff;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 15px;
}

.breadcrumb {
    background-color: transparent;
    padding: 17px 0;
}

.page-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    margin: 30px 0 40px 0;
}

.personal-info-title {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 42px;
    letter-spacing: 0;
    line-height: 52px;
    padding: 30px 0 22px 0;
}

.head {
    background: linear-gradient(201.88deg, #EAF6F7 0%, #EAF6F7 100%);
}

@media (max-width: 768px) {
    .personal-info-title {
        font-size: 24px;
        line-height: 30px;
        margin: 55px 0 26px 0;
        padding: 30px 0px 7px 0px;
    }

    .page-title {
        font-size: 24px;
        line-height: 30px;
        margin: 80px 0 26px 0;
    }

    .pds-card .card-body {
        padding: 24px 20px;
    }

    .follow-us h4,
    .contact .header,
    .footer-download-app .heading,
    .contact .info p,
    .address .header,
    .address .info p,
    .pds-checkbox>label {
        font-size: 16px !important;
        line-height: 22px !important;
    }

    .footer-office-details .time {
        font-size: 13px !important;
        line-height: 18px !important;
    }

    .footer-copyright-text p {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .pds-checkbox>label {
        padding: 4px 4px 4px 32px !important;
    }
}

.card-second-row {
    margin-top: 24px;
}

.general-section .row>div:nth-child(-n+3) {
    margin-bottom: 24px;
}

.pds-checkbox>input+label:before {
    top: 5px;
}

.pds-checkbox>input+label:after {
    top: 6.1px;
}

.list-unstyled {
    margin: 0;
    padding: 0;
}

.langPreference {
    margin-top: 12px;
}

.address-section select,
.address-section input {
    margin-bottom: 24px;
}

.select-arrow {
    background-image: url("assets/Icon_Arrow_Down.svg") !important;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 90%;
}

.margin-left-15 {
    margin-left: 15px;
}

.contact-section select {
    margin-bottom: 24px;
}

.rsc-card {
    width: 100%;
}

@media (max-width: 768px) {
    .rsc-card .row>[class*="col-"] {
        margin-bottom: 24px;
    }

    .rsc-card .row>div:last-child {
        margin-bottom: 0;
    }

    .card-second-row {
        margin-top: 0;
    }

    .info-title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 35px;
    }

    .left-align {
        font-size: 16px;
        line-height: 22px;
    }

    .other-con-note-title {
        margin-bottom: 12px;
    }

    .contact-section select {
        margin-bottom: 0;
    }

    .mobile-number {
        margin-top: 0;
    }

    .contact-section input {
        margin-bottom: 24px;
    }

    .address-section select,
    .address-section input {
        margin-bottom: 0;
    }
}

.form-control:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.rsc-select:focus {
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 5px rgb(128 189 255 / 50%) !important;
}

.pds-checkbox>input:checked+label:after,
.pds-checkbox>input:checked:disabled+label:after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 5px;
    width: 8px;
    height: 13.6px;
    border: solid #0091da;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

.pds-checkbox>input:checked+label:before,
.pds-checkbox>input:checked:disabled+label:before {
    background-color: #ffffff;
}

.pds-checkbox>input:checked:disabled+label:after {
    border: solid #464646;
    border-width: 0 2px 2px 0;
}

.pds-checkbox>input+label:before,
.pds-checkbox>input+label:after {
    height: 20px;
    width: 20px;
}

.pds-checkbox>input:checked:disabled+label:after {
    background-image: none;
}

@media screen and (min-width: 991px) {

    .hk-link:focus,
    .hk-link:active {
        border-bottom: 2px solid #0091da;
        color: #0091da;
        text-decoration: none;
        cursor: pointer;
    }

    .hk-link-inactive:active {
        border-bottom: none;
    }

    .hk-dropdown-menu {
        height: auto;
        border-radius: 15px;
        background-color: #ffffff;
        box-shadow: 0 2px 5px 0 rgb(70 78 126 / 3%), 0 5px 30px 0 rgb(70 78 126 / 8%);
        top: 100%;
        margin: 0;
        border: none;
        left: auto
    }
}

.dropdown-list-item {
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    padding: 14px 30px !important;
}

@media screen and (max-width:480px) {
    .dropdown-list-item {
        white-space: initial;
    }
}

.dropdown-list-item:hover {
    color: #0091da;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .hk-link::before {
        content: " ";
        position: absolute;
        top: 25px;
        right: 20px;
        background: url("./assets/Nav_Collapse\ Copy\ 2.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
    }

    .nav-item.show>.hk-link::before {
        content: " ";
        position: absolute;
        top: 25px;
        right: 20px;
        background: url("./assets/Nav_Collapse\ Copy\ 4.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
    }

    .hk-profile-link::before {
        top: 30px !important;
    }

    .inline-display {
        display: inline-block !important;
    }
}

.logout-text {
    color: #767676;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

.clientNmbrTxt {
    color: #0091DA;
    font-family: "FS Elliot Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
}

.accountTypeTxt {
    color: #464646;
    font-family: "FS Elliot Pro";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    white-space: initial;
    text-transform: capitalize;
}

.border-bottom {
    border-bottom: 1px rgba(70, 78, 126, 0.1);
}

.border-bottom-none {
    border-bottom: none;
}

.blue-arrow {
    color: #0091da;
}

.dropdown-list-item:active {
    color: #0091da !important;
    text-decoration: none;
    background-color: transparent;
}

.pds-error:before {
    padding-bottom: 10px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23E74E1C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle' aria-hidden='true'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
}

.common-pds-error {
    color: #e74e1c !important;
    font-family: FS Elliot Pro !important;
    font-size: 13px !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
    margin-top: 15px !important;
    margin-left: 0px !important;
}

.inputerrorstyle {
    border: 1px solid #e74e1c !important;
}

.inputerrorstyle:focus {
    border-color: #e74e1c !important;
    box-shadow: none !important;
}

.Toastify__toast {
    border-radius: 4px;
    background-color: #333;
    padding: 0px;
}

.Toastify__toast-container {
    width: min(530px, 100vw);
    width: 530px !important;
    max-width: 530px !important;
}

.Toastify__toast-body {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    border-radius: 10px;
}

.Toastify__toast {
    padding: 0px !important;
}

.toast_message__3jL-W {
    color: #ffffff;
    font-family: "FS Elliot Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.toast_container__3k5Pa {
    background: #00a29b !important;
    color: #00a29b !important;
    background-color: #00a29b !important;
    border-radius: 10px;
}

.Toastify__toast--default {
    background: #00a29b !important;
    color: #00a29b !important;
    background-color: #00a29b !important;
    border-radius: 10px;
}

.Toastify__toast {
    border-radius: 10px !important;
}

@media (min-width: 576px) and (max-device-width: 767px) {
    .Toastify__toast-container {
        width: 508px !important;
    }
}

@media (min-width: 481px) and (max-device-width: 575px) {
    .Toastify__toast-container {
        width: 96% !important;
    }
}

@media (min-width: 0px) and (max-device-width: 480px) {
    .Toastify__toast-container {
        max-width: 100% !important;
        padding: 16px !important;
    }
}

.document-breadcrumb-item,
.breadcrumb-item {
    font-family: "FS Elliot Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.img-margin {
    margin: 0px !important;
}

.subBreadcr {
    color: #464646 !important;
}

.landing-hr {
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgba(0, 145, 218, 0.2);
    border: 1px solid rgba(0, 145, 218, 0.2);
}


/* Hide scrollbar for Chrome, Safari and Opera */

.hide-scroll::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.hide-scroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@media only screen and (max-width: 768px) {
    .inner-label {
        font-size: 16px;
        line-height: 22px;
    }
}

@media screen and (max-width: 767px) {
    .info-card-content {
        padding-top: 24px;
    }

    .info-card-content-aiib {
        padding-top: 24px;
    }

    .sub-navigation a {
        padding-top: 17px;
        padding-bottom: 16px;
    }
}

.contect_info_error {
    padding-left: 24px !important;
    margin-top: -18px !important;
    color: #e74e1c !important;
    font-family: FS Elliot Pro !important;
    font-size: 13px !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
    margin-left: 0px !important;
}

@media (min-width: 767px) and (max-device-width: 768px) {
    .contact-info-input-ipad {
        margin-top: 28px !important;
    }
}

.risk-indicator {
    border-radius: 2px;
    margin-left: 25px;
    margin-top: 25px;
    height: 12px;
    width: 12px;
    margin-right: 8px;
}

.ss-spectrum {
    background: linear-gradient(90deg, #A0E2F7 7%, #8EE6B6 28.38%, #F7DA97 53.61%, #FF774A 100%);
    height: 30px;
}

.polygon-progress-top {
    -webkit-clip-path: polygon(1% 0, 99% 0, 100% 55%, 99% 100%, 1% 100%, 0% 55%);
    clip-path: polygon(1% 0, 99% 0, 100% 55%, 99% 100%, 1% 100%, 0% 55%);
    margin-top: 10px;
}

@media (min-width:768px) and (max-width:991px) {
    .polygon-progress-top {
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 55%, 98% 100%, 2% 100%, 0% 55%);
        clip-path: polygon(2% 0, 98% 0, 100% 55%, 98% 100%, 2% 100%, 0% 55%);
    }
}

.ss-low-risk {
    background-color: #A0E2F7;
}

.dis-risk {
    background-color: #C7C7C7;
}

.high-risk {
    background-color: #E74E1C;
}

.moderate-to-high-risk {
    background-color: #FF9231;
}

.moderate-risk {
    background-color: #FAC800;
}

.low-risk {
    background-color: #8EE6B6;
}

@media screen and (max-width:767px) {
    .polygon-progress-top {
        -webkit-clip-path: inset(1% round 20px);
        clip-path: inset(1% round 20px);
    }
}

.img-info {
    cursor: pointer;
    margin-left: 7px;
}

.pds-modal-dialog-overlay {
    background-color: rgba(234, 246, 247, 0.5) !important;
}

.pds-modal .pds-modal-dialog {
    border-radius: 10px;
    padding: 55px 40px !important;
}

.pointer-cursor {
    cursor: pointer;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: auto !important;
}

button:focus {
    outline: none;
}

.bold-text {
    font-weight: bold;
}

.underline-text {
    text-decoration: underline;
}

.text-red {
    color: #e74e1c !important;
}
/*superlogon specific*/
.pds-tab .pds-tab-panel {
    background-color: transparent;
    color: #464646;
    border: none;
    padding: 30px 25px 0 30px !important;
}
